// extracted by mini-css-extract-plugin
export var pieces = "styles-module--pieces--2Bln4";
export var pImage = "styles-module--pImage--HHyBM";
export var pImage1 = "styles-module--pImage1--3a6kL";
export var float = "styles-module--float--21-3_";
export var pImage2 = "styles-module--pImage2--znDTu";
export var pImage3 = "styles-module--pImage3--3zc0b";
export var pImage4 = "styles-module--pImage4--26bpf";
export var pImage5 = "styles-module--pImage5--2Nr4p";
export var spin = "styles-module--spin--dAySj";
export var pImage6 = "styles-module--pImage6--CW8Hn";
export var fadeIn = "styles-module--fadeIn--12q27";
export var pImage7 = "styles-module--pImage7--Of2Kj";