import React from "react"
import { Header } from ".."
import Footer from "../Footer"
import { main, wrapper } from "./styles.module.css"
import "./globalStyles.css"
import { Helmet } from "react-helmet"
import SwiperCore, { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"

SwiperCore.use([Autoplay, EffectFade])

export const Layout = ({ children, title = "Still Curious" }) => (
  <main className={wrapper}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
    </Helmet>
    <Header />
    <section className={main}>{children}</section>
    <Footer />
  </main>
)

export default Layout
