import React from "react"
import { RichText } from "prismic-reactjs"
import { AnFadeIn } from "components"
import { article, twoColArticle } from "./styles.module.css"

const Block = ({ title, subtitle, leftContent, rightContent }) => {
  return (
    <AnFadeIn>
      <article className={article}>
        <header>
          <div>
            <h1>{title}</h1>
          </div>
          <div>
            <p>{subtitle}</p>
          </div>
        </header>
        <section className={twoColArticle}>
          <section>
            <RichText render={leftContent} />
          </section>
          <section>
            <RichText render={rightContent} />
          </section>
        </section>
      </article>
    </AnFadeIn>
  )
}

export default Block
