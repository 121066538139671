import { Link } from "gatsby"
import React, { useState } from "react"
import { logo } from "images"
import {
  mobileButton,
  active,
  mobileBody,
  bodyActive,
  headerDesktop,
  layout,
  list,
  logoClass,
  headerMobile,
  head,
  mobileLogo,
  headerMobileBar1,
  headerMobileBar2,
  headerMobileBar3,
  mobileList,
  rightList,
} from "./styles.module.css"
import classNames from "classnames"
import { navigate } from "gatsby-link"
import { StaticQuery, graphql } from "gatsby"

const Header = ({ data }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const mobileCx = classNames(mobileButton, {
    [active]: mobileOpen,
  })

  const mobileBodyCx = classNames(mobileBody, {
    [bodyActive]: mobileOpen,
  })

  const handleNavigate = link => {
    navigate(link)
    setMobileOpen(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query GetActiveJobs {
          allPrismicJob(filter: { data: { active: { eq: true } } }) {
            nodes {
              id
            }
          }
        }
      `}
      render={data => (
        <>
          <header className={headerDesktop}>
            <section className={layout}>
              <div>
                <ul className={list}>
                  <li>
                    <Link to="/approach">Approach</Link>
                  </li>
                  <li>
                    <Link to="/attitude">Attitude</Link>
                  </li>
                  <li>
                    <Link to="/journal">Journal</Link>
                  </li>
                </ul>
              </div>
              <div className={logoClass}>
                <Link to="/">
                  <img src={logo} alt="Still Curious Logo" />
                </Link>
              </div>
              <ul className={`${list} ${rightList}`}>
                <li>
                  {data.allPrismicJob.nodes.length > 0 && (
                    <>
                      <Link to="/work">Work with us</Link>
                    </>
                  )}
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </section>
          </header>

          <header className={headerMobile}>
            <div className={head}>
              <div></div>
              <div className={mobileLogo}>
                <Link to="/">
                  <img src={logo} alt="Still Curious Logo" />
                </Link>
              </div>
              <div
                className={mobileCx}
                onClick={() => setMobileOpen(!mobileOpen)}
              >
                <span className={headerMobileBar1}></span>
                <span className={headerMobileBar2}></span>
                <span className={headerMobileBar3}></span>
              </div>
            </div>
            <div className={mobileBodyCx}>
              <ul className={mobileList}>
                <li>
                  <span onClick={() => handleNavigate("/approach")}>
                    Approach
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigate("/attitude")}>
                    Attitude
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigate("/journal")}>
                    Journal
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigate("/work")}>
                    Work with us
                  </span>
                </li>
                <li>
                  <span onClick={() => handleNavigate("/contact")}>
                    Contact
                  </span>
                </li>
              </ul>
            </div>
          </header>
        </>
      )}
    />
  )
}

export default Header
