// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-index-js": () => import("./../../../src/pages/approach/index.js" /* webpackChunkName: "component---src-pages-approach-index-js" */),
  "component---src-pages-attitude-index-jsx": () => import("./../../../src/pages/attitude/index.jsx" /* webpackChunkName: "component---src-pages-attitude-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-index-jsx": () => import("./../../../src/pages/journal/index.jsx" /* webpackChunkName: "component---src-pages-journal-index-jsx" */),
  "component---src-pages-journal-prismic-article-uid-js": () => import("./../../../src/pages/journal/{PrismicArticle.uid}.js" /* webpackChunkName: "component---src-pages-journal-prismic-article-uid-js" */),
  "component---src-pages-prismic-page-uid-js": () => import("./../../../src/pages/{PrismicPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-page-uid-js" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */)
}

