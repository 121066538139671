import { button, transparentClass, navyClass } from "./styles.module.css"
import React from "react"
import classNames from "classnames"

const Button = ({ title, onClick, transparent, navy, className }) => {
  const cx = classNames(button, className, {
    [transparentClass]: transparent,
    [navyClass]: navy,
  })
  return (
    <button className={cx} onClick={onClick}>
      {title}
    </button>
  )
}

export default Button
