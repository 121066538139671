import React from "react"
import { logoFlat, planet } from "images"
import { footer, logo, address } from "./styles.module.css"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

const Footer = () => {
  const date = new Date()
  return (
    <StaticQuery
      query={graphql`
        query AllPageQury {
          allPrismicPage {
            nodes {
              url
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
      `}
      render={data => (
        <footer className={footer}>
          <img src={logoFlat} className={logo} alt="" />
          <address className={address}>
            37 Warren Street, London, United Kingdom, W1T 6AD.
            <br />
            {date.getFullYear()} Still Curious Ltd. ALL RIGHTS RESERVED.
            <br />
            {data.allPrismicPage.nodes.map(el => (
              <>
                <Link key={el.uid} to={`/${el.uid}`}>
                  {el.data.title.text}
                </Link>
                <br />
              </>
            ))}
          </address>
          <br />
          <img src={planet} height="60px" />
        </footer>
      )}
    />
  )
}

export default Footer
