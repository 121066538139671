import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import "gatsby-plugin-prismic-previews/dist/styles.css"
import { Layout } from "./src/components"
import { linkResolver } from "./src/utils/linkResolver"

export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
