import {
  story,
  imageHolder,
  imageClass,
  titleClass,
  textClass,
  readMore,
} from "./styles.module.css"
import React from "react"
import { Link } from "gatsby"
// import Imgix from "react-imgix"

const Story = ({ image, title, text, href }) => {
  return (
    <Link to={href}>
      <div className={story}>
        <div className={imageHolder}>
          <img className={imageClass} src={image} />
        </div>
        <h3 className={titleClass}>{title}</h3>
        <p className={textClass}>{text}</p>
        <p className={readMore}>Read more ... </p>
      </div>
    </Link>
  )
}

export default Story
