import React from "react"
import {
  pieces,
  pImage,
  pImage1,
  pImage2,
  pImage3,
  pImage4,
  pImage5,
  pImage6,
  pImage7,
} from "./styles.module.css"
import {
  redBlobCut,
  redBlob,
  blueBlobCut,
  blueBlob,
  eventsYellow,
  lineDrawing,
  squiggle,
} from "images"

const Pieces = () => {
  return (
    <section className={pieces}>
      <img src={redBlobCut} className={`${pImage} ${pImage1}`} />
      <img src={redBlob} className={`${pImage} ${pImage2}`} />
      <img src={blueBlobCut} className={`${pImage} ${pImage3}`} />
      <img src={blueBlob} className={`${pImage} ${pImage4}`} />
      <img src={eventsYellow} className={`${pImage} ${pImage5}`} />
      <img src={lineDrawing} className={`${pImage} ${pImage6}`} />
      <img src={squiggle} className={`${pImage} ${pImage7}`} />
    </section>
  )
}

export default Pieces
