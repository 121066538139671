import { buttonLink, transparentClass, navyClass } from "./styles.module.css"
import React from "react"
import classNames from "classnames"

const ButtonLink = ({
  title,
  to,
  transparent,
  navy,
  className,
  target = "self",
}) => {
  const cx = classNames(buttonLink, className, {
    [transparentClass]: transparent,
    [navyClass]: navy,
  })
  return (
    <a className={cx} href={to} target={target}>
      {title}
    </a>
  )
}

export default ButtonLink
