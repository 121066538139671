/* eslint-disable no-undef */
exports.linkResolver = doc => {
  if (doc.type === "clients") {
    return `/work/${doc.uid}`
  }

  if (doc.type === "page") {
    return `/${doc.uid}`
  }

  return "/"
}
